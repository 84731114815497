import { toast } from "react-toastify";

const ToastHelper = {
  showSuccess: (message) => {
    toast.success(message);
  },

  showError: (message) => {
    toast.error(message);
  },
};

export default ToastHelper;
