import BigInputField from 'general/custom-fields/BigInputField';
import { FastField, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { AppIcons } from 'general/constants/AppResource';
import { useTranslation } from 'react-i18next';

SignInForm.propTypes = {
    onSubmit: PropTypes.func,
    onSignInGoogle: PropTypes.func,
};

SignInForm.defaultProps = {
    onSubmit: null,
    onSignInGoogle: null,
}

function SignInForm(props) {
    // MARK: --- Params ---
    const { t } = useTranslation();
    const initialValues = {
        email: '',
        password: '',
    }
    const validationSchema = Yup.object().shape({
        email: Yup.string().required(t('EmailIsRequired')).email(t('EmailInvalid')),
        password: Yup.string().required(t('PasswordIsRequired')),//.min(6, t('PasswordLengthMin')),
    });
    const { onSubmit, onSignInGoogle } = props;
    const { isSigningIn } = useSelector(state => state.auth);

    function handleSignInGoogle() {
        if (onSignInGoogle) {
            onSignInGoogle();
        }
    }

    return (
        <Formik
            initialValues={initialValues}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
        >

            {formikProps => {
                const { values, errors, touched, isSubmitting } = formikProps;
                // console.log({ values, errors, touched });

                return (
                    <Form>

                        {/* begin: Title */}
                        <div className="pb-5 pb-lg-15">
                            <h3 className="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">{t('AdminPage')}</h3>
                            <div className="text-muted font-weight-bold font-size-h5">{`${t('SignInToUse')}`}
                                {/* <a href="#" className="text-primary font-weight-bolder ml-2">{t('Register')}</a> */}
                            </div>
                        </div>
                        {/* end: Title */}

                        <FastField
                            name="email"
                            component={BigInputField}
                            label={t('Email')}
                            placeholder={t('PlaceHolderEmail')}
                        />

                        <FastField
                            name="password"
                            component={BigInputField}
                            type="password"
                            label={t('Password')}
                            placeholder={t('PlaceHolderPassword')}
                        />

                        <div className="pb-lg-0 pb-5">
                            <button
                                type="submit"
                                className={`btn btn-primary font-weight-bolder font-size-h6 py-4 my-3 mr-3 ${isSigningIn ? 'spinner spinner-right spinner-white' : ''}`}>
                                {t('SignIn')}
                            </button>
                            {/* <button
                                type="button"
                                className="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                                onClick={handleSignInGoogle}>
                                <span className="svg-icon svg-icon-md">
                                    <img src={AppIcons.icGoogle} alt="Google" />
                                </span>
                                {t('SignInWithGoogle')}
                            </button> */}
                        </div>
                    </Form>
                );
            }}

        </Formik >
    );
}

export default SignInForm;