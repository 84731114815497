import ruleApi from 'api/ruleApi';
import Global from 'general/Global';

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Get rule list
export const thunkGetRuleList = createAsyncThunk('rule/list', async (params, thunkAPI) => {
    const res = await ruleApi.getRuleList(params);
    return res.data;
});

// Update rule info
export const thunkUpdateRuleInfo = createAsyncThunk('rule/update', async (params, thunkAPI) => {
    const res = await ruleApi.updateRuleInfo(params.ruleId, params.values);
    return res.data;
});

// Create rule
export const thunkCreateRule = createAsyncThunk('rule/create', async (params, thunkAPI) => {
    const res = await ruleApi.createRule(params);
    return res.data;
});

// Delete rule
export const thunkDeleteRule = createAsyncThunk('rule/delete', async (ruleId, thunkAPI) => {
    const res = await ruleApi.deleteRule(ruleId);
    return res.data;
});

// user info
export const thunkGetRuleInfo = createAsyncThunk('rule/info', async (ruleId) => {
    return (await ruleApi.getRuleInfo(ruleId)).data
})

const ruleSlice = createSlice({
    name: 'rule',
    initialState: {
        isGettingRuleList: false,
        rules: [],
        pagination: { perPage: Global.gDefaultPagination },

        isUpdatingRule: false,
        isCreatingRule: false,
        isDeletingRule: false,
    },
    reducers: {
        setPaginationPerPage: (state, action) => {
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    perPage: action.payload,
                }
            }
        },
    },
    extraReducers: {
        // Get rule list
        [thunkGetRuleList.pending]: (state, action) => {
            state.isGettingRuleList = true;
        },
        [thunkGetRuleList.rejected]: (state, action) => {
            state.isGettingRuleList = false;
        },
        [thunkGetRuleList.fulfilled]: (state, action) => {
            state.isGettingRuleList = false;
            const { result, rules, total, count, page } = action.payload;
            if (result === 'success') {
                if (rules) {
                    state.rules = rules;
                }
                state.pagination = {
                    ...state.pagination,
                    total: total,
                    count: count,
                    currentPage: page + 1,
                };
            }
        },

        // Update rule info
        [thunkUpdateRuleInfo.pending]: (state, action) => {
            state.isUpdatingRule = true;
        },
        [thunkUpdateRuleInfo.rejected]: (state, action) => {
            state.isUpdatingRule = false;
        },
        [thunkUpdateRuleInfo.fulfilled]: (state, action) => {
            state.isUpdatingRule = false;
            const { result, rule } = action.payload;
            if (result === 'success' && rule) {
                const rules = current(state).rules;
                const ruleId = rule.ruleId;
                const ruleIndex = rules.findIndex(item => item.ruleId == ruleId);
                const foundRule = rules.find(item => item.ruleId == ruleId);
                console.log({ rule, foundRule });
                if (ruleIndex > 0) {
                    state.rules[ruleIndex] = { ...foundRule, ...rule };
                }
            }
        },

        // Create rule
        [thunkCreateRule.pending]: (state, action) => {
            state.isCreatingRule = true;
        },
        [thunkCreateRule.rejected]: (state, action) => {
            state.isCreatingRule = false;
        },
        [thunkCreateRule.fulfilled]: (state, action) => {
            state.isCreatingRule = false;
        },

        // Delete rule
        [thunkDeleteRule.pending]: (state, action) => {
            state.isDeletingRule = true;
        },
        [thunkDeleteRule.rejected]: (state, action) => {
            state.isDeletingRule = false;
        },
        [thunkDeleteRule.fulfilled]: (state, action) => {
            state.isDeletingRule = false;
        },
    },
});

const { reducer, actions } = ruleSlice;
export const { setPaginationPerPage } = actions;
export default reducer;