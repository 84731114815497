import axiosClient from './axiosClient';

const ruleApi = {
    // Get user list
    getRuleList: (params) => {
        const url = 'rule';
        return axiosClient.get(url, { params });
    },

    // Get user info by id
    getRuleInfo: (ruleId) => {
        const url = `rule/${ruleId}`;
        return axiosClient.get(url);
    },

    // Update user info
    updateRuleInfo: (ruleId, params) => {
        const url = `rule/${ruleId}`;
        // const formData = new FormData();
        // formData.append('ruleId', ruleId);
        // console.log(formData);
        // for (const [key, value] of Object.entries(params)) {
        //     formData.append(key, value);
        // }

        return axiosClient.put(url, params, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Create new user
    createRule: (params) => {
        const url = `rule/add`;
        // return axiosClient.post(url, params);
        // const formData = new FormData();
        // for (const [key, value] of Object.entries(params)) {
        //     formData.append(key, value);
        // }
        return axiosClient.post(url, params, {
            headers: { "Content-Type": "multipart/form-data" }
        });
    },

    // Delete users
    deleteRule: (ruleId) => {
        const url = `rule/delete`;
        return axiosClient.post(url, {
            ruleId: ruleId
        });
    },

    getStage: (options) => {
        const url = `rule/getStage`;
        return axiosClient.post(url, {
            options
        });
    }
}

export default ruleApi;