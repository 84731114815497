import authReducer from 'app/authSlice'
import appReducer from 'app/appSlice'
import ruleReducer from 'features/Dashboard/ruleSlice'
import getStageRuleSlice from 'features/Dashboard/getStageRuleSlide'

const { configureStore, compose, applyMiddleware, Middleware } = require("@reduxjs/toolkit")

const rootReducer = {
    app: appReducer,
    auth: authReducer,
    rule: ruleReducer,
    stages: getStageRuleSlice,
}

const logger: Middleware = _store => next => action => {
    // console.log('[Redux Logger Middleware-Update State]')
    // console.log('[Action]', action.type, action.payload)
    return next(action)
}

const store = configureStore({
    reducer: rootReducer,
    devTools: true,
    enhancers: [compose(applyMiddleware(logger))],
})

export default store