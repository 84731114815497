import React from 'react'
import logo from './logo.svg';
import './App.css';
import NotFound from './general/components/NotFound'
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom'
import { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import Loading from 'general/components/Loading'
import SignInScreen from 'features/Auth/screens/SignIn'
import _ from "lodash"
import UserHelper from 'general/helpers/UserHelper'
import { useDispatch, useSelector } from 'react-redux'
import { thunkGetCurrentUserInfo, thunkSignOut } from 'app/authSlice'
import PreferenceKeys from 'general/constants/PreferenceKeys'
import { unwrapResult } from '@reduxjs/toolkit'
import Utils from 'general/utils/Utils'
import 'bootstrap/dist/css/bootstrap.min.css';

window._ = _
global._ = _

window.jQuery = window.$ = require('jquery')
window.moment = require('moment')
global.moment = window.moment

require("assets/plugins/ktutil")
require("assets/plugins/ktoffcanvas")
require("assets/plugins/kttoggle")
require("assets/plugins/ktmenu")
require("assets/plugins/ktcookie")
require("assets/plugins/ktcard")
require("assets/plugins/ktwizard")
require("assets/plugins/ktapp")

const Dashboard = React.lazy(() => import('./features/Dashboard'));

function App() {
  const sTag = '[App]'
  const dispatch = useDispatch()
  const currentUser = useSelector(state => state.auth.currentUser)
  const { t } = useTranslation()

  // MARK: --- Functions ---
  async function getCurrentUserInfo() {
    try {
      const resGetCurrentUserInfo = unwrapResult(await dispatch(thunkGetCurrentUserInfo()))
      // Can not get info => Need sign out and back to sign-in screen
      // console.log({ resGetCurrentUserInfo });
    } catch (error) {
      console.log(`${sTag} getCurrentUserInfo error: ${error.message}`)
      // Can not get info => Need sign out and back to sign-in screen
      signOutAndBack()
    }
  }

  async function signOutAndBack() {
    try {
      const resSignOut = unwrapResult(await dispatch(thunkSignOut()))
    } catch (error) {
      console.log(`${sTag} sign out error: ${error.message}`)
      localStorage.removeItem(PreferenceKeys.accessToken)
      localStorage.removeItem(PreferenceKeys.accessTokenExpired)
    }
    window.location.href = '/sign-in'
  }

  useEffect(() => {
    console.log(`${sTag} viewDidLoad`)

    return () => {
      console.log(`${sTag} viewWillDismiss`)
    }
  }, [])

  useEffect(() => {
    // Check to get current user info
    if (Utils.isObjectEmpty(currentUser) && localStorage.getItem(PreferenceKeys.accessToken)) {
      getCurrentUserInfo()
    }
  }, [currentUser])

  return (
    <>
      <BrowserRouter>
        <Suspense fallback={<div className='d-flex align-items-center justify-content-center pt-10'><Loading message={t('Loading')} /></div>}>
          <Switch>
            <Redirect exact from='/' to='/dashboard' />

            <GuestRoute exact path='/sign-in' component={SignInScreen} />

            <PrivateRoute path='/dashboard' component={Dashboard} />

            <Route component={NotFound} />

          </Switch>
        </Suspense>
      </BrowserRouter>
    </>
  );
}

// function PrivateRoute({ component: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => (
//         UserHelper.checkAccessTokenValid()
//           ? <Component {...props} />
//           : <Redirect to="/sign-in" />
//       )}
//     />
//   )
// }

function PrivateRoute(props) {
  const isAuth = UserHelper.checkAccessTokenValid()
  return isAuth ? <Route {...props} /> : <Redirect to='/sign-in' />
}

function GuestRoute(props) {
  const isAuth = UserHelper.checkAccessTokenValid()
  return isAuth ? <Redirect to="/" /> : <Route {...props} />;
}

// function GuestRoute({ element: Component, ...rest }) {
//   return (
//     <Route
//       {...rest}
//       render={(props) => {
//         console.log(11111)
//         UserHelper.checkAccessTokenValid()
//           ? <Redirect to="/" />
//           : <Component {...props} />
//       }}
//     />
//   )
// }

export default App;
