import { AppIcons } from "./AppResource";
import {NotificationType,MgIdentifyStatus} from 'general/types/index.d'
// const casual = require('casual-browserify');

const AppData = {
    /**
     * @type {{type:'item'|'section',text:String,icon:String,path:String,subMenu:{text:String,path:String}[]}[]}
     */
    sidebarMenuItems: [
        { type: 'section', text: 'Summary', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Dashboard', icon: 'fad fa-home-alt', path: '/dashboard' },
        { type: 'section', text: 'Manage', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'User', icon: 'fad fa-user', path: '/user' },
        // { type: 'item', text: 'Member', icon: 'fad fa-users', path: '/member' },
        { type: 'item', text: 'Transaction', icon: 'fad fa-shopping-cart', path: '/transaction' },
        { type: 'item', text: 'Research', icon: 'fad fa-analytics', path: '/research' },
        { type: 'item', text: 'Course', icon: 'fad fa-book', path: '/course' },
        {
            type: 'item', text: 'IdeaLists', icon: 'fad fa-list', path: '/ideas',
            subMenu: [
                { text: 'Top 50', path: '/ideas/top50' },
                { text: 'CurrentIdeas', path: '/ideas/current' },
                { text: 'BuypointIdeas', path: '/ideas/buypoint' },
                { text: 'AddedIdeas', path: '/ideas/added' },
                { text: 'RemovedIdeas', icon: 'fad fa-handshake', path: '/ideas/removed' },
                { text: 'BuyQueueIdeas', path: '/ideas/buy-queue' },
                { text: 'SellQueueIdeas', path: '/ideas/sell-queue' },
                { text: 'Buy Watch Lists', path: '/ideas/buy-watch' },
                { text: 'Sell Watch Lists', path: '/ideas/sell-watch' },
            ],
        },
        {
            type: 'item',
            text: 'KnowledgeCenter',
            icon: 'fad fa-school',
            path: '/knowledge-center',
            subMenu: [
                { text: 'Categories', path: '/knowledge-center/categories' },
                { text: 'Articles', path: '/knowledge-center/articles' },
                { text: 'Videos', path: '/knowledge-center/videos' },
                { text: 'Books', path: '/knowledge-center/books' },
                { type: 'item', text: 'Webinars', icon: 'fad fa-handshake', path: '/knowledge-center/webinar' },
                { text: 'InvestingUniversity', path: '/knowledge-center/investing-university' },
            ],
        },
        { type: 'item', text: 'Event', icon: 'fas fa-calendar-day', path: '/event' },
        /* Stock reated */
        { type: 'section', text: 'Stock related', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Industry', icon: 'fad fa-industry', path: '/industry' },
        { type: 'item', text: 'Company', icon: 'fad fa-building', path: '/company' },
        { type: 'item', text: 'Report Data', icon: 'fad fa-file-invoice', path: '/reportData' },
        { type: 'item', text: 'Filter', icon: 'fad fa-filter', path: '/filter' },
        { type: 'item', text: 'MG Identify', icon: 'far fa-newspaper', path: '/mgIdentify'},
        // {
        //     type: 'item', text: 'Stock', icon: 'fad fa-chart-area', path: '/',
        //     subMenu: [
        //         // { text: 'Stock Category', path: '/stockCategory' },
        //     ],
        // },
        { type: 'section', text: 'Other', icon: 'fad fa-ellipsis-h' },
        { type: 'item', text: 'Notification', icon: 'fad fa-bell', path: '/notification' },
        { type: 'item', text: 'Banner', icon: 'fad fa-images', path: '/content/banner' },
        // {
        //     type: 'item', text: 'Content', icon: 'fad fa-file', path: '/content',
        //     subMenu: [
        //         { text: 'Banner', path: '/content/banner' },
        //         // { text: 'Portfolio', path: '/content/portfolio' },
        //     ],
        // },
        { type: 'item', text: 'BankAccount', icon: 'fad fa-dollar-sign', path: '/bankAccount' },
        { type: 'item', text: 'FAQ', icon: 'fad fa-question-circle', path: '/faq' },
        { type: 'item', text: 'FeaturedReview', icon: 'fas fa-star', path: '/review' },
        { type: 'item', text: 'Products', icon: 'fas fa-th', path: '/product' },
        // { type: 'item', text: 'Sales', icon: 'fas fa-chart-line', path: '/sales' },
        // { type: 'item', text: 'Policy', icon: 'fas fa-book-open', path: '/policy' },
    ],

    languages: [
        {
            icon: AppIcons.icFlagUs,
            title: "English",
            code: "en",
        },
        {
            icon: AppIcons.icFlagVi,
            title: "Vietnamese",
            code: "vi",
        }
    ],
    courseLevels: [
        {text:"All", value: -1},
        {
            text: 'Basic',
            value:1
        },
        {
            text: 'Intermediate',
            value:2
        },
        {
            text: 'Advanced',
            value:3
        }
    ],
    accessLevels: [
        {
            value: -1,
            text: 'All',
        },
        {
            value: 0,
            text: 'Admin',
        },
        {
            value: 1,
            text: 'Subscribed',
        },
        {
            value: 2,
            text: 'Registered',
        },
        {
            value: 3,
            text: 'Guest',
        },
    ],

    articleTypes: [
        {
            value: 'ARTICLE',
            text: 'ARTICLE'
        },
        {
            value: 'RESEARCH',
            text: 'RESEARCH'
        }
    ],

    // sampleCategories: (Array(20).fill({})).map((item, index) => {
    //     return {
    //         categoryId: index,
    //         name: casual.name,
    //         description: casual.description,
    //     }
    // }),

    // sampleArticles: (Array(20).fill({})).map((item, index) => {
    //     return {
    //         articleId: index,
    //         categoryId: casual.integer(0, 1000),
    //         title: casual.title,
    //         description: casual.description,
    //         date: casual.date('YYYY-MM-DD HH:mm:ss'),
    //         content: casual.text,
    //         author: casual.full_name,
    //         accessLevel: casual.integer(0, 3),
    //         type: casual.random_element(['ARTICLE', 'RESEARCH']),
    //     }
    // }),

    // Pagination per page items
    perPageItems: [
        { value: 5 },
        { value: 10 },
        { value: 20 },
        { value: 30 },
        { value: 50 },
        { value: 100 },
    ],

    webinarStatus :[
        {text:'All',value:-1},
        {text:'OPEN',value:0},
        {text:'CLOSED',value:1},
        {text:'FINISHED',value:2},
        {text:'CANCELLED',value:3},
    ],

    bannerType: [
        {
            value: "DEFAULT",
            text: 'Default',
        },
        {
            value: "BANNER_9_16",
            text: "banner_9_16"
        },
        {
            value: "BANNER_3_4",
            text: "banner_3_4"
        },
    ],
    /**
     * @type {{text:NotificationType, value:number}[]}
     */
    notificationType:[ 
        { text: "DEFAULT", value: 0 },
        { text: "WARNING", value: 1 },
    ],
    /**
     * @type {{text: MgIdentifyStatus,value:Number}[]}
     */
    mgIdentifyStatus:[
        {text:'NEGATIVE',value:0},
        {text:'NORMAL',value:1},
        {text:'POSITIVE',value:2}
    ],

    // chart color list
    chartColors: ['#007aff', '#ff2d55', '#5856d6', '#ff9500', '#ffcc00', '#ff3b30', '#5ac8fa', '#4cd964'],
};

export default AppData;
