import { removeAxiosAccessToken } from "api/axiosClient";
import AppData from "general/constants/AppData";
import { AppImages } from "general/constants/AppResource";
import PreferenceKeys from "general/constants/PreferenceKeys";
import { Account } from 'general/types/index.d'
import moment from 'moment';
import { useTranslation } from "react-i18next";

const UserHelper = {
    // Get access level text
    getAccessLevelText: (accessLevel) => {
        const filteredItems = AppData.accessLevels.filter(item => item.value === accessLevel);
        if (filteredItems && filteredItems.length > 0) {
            return filteredItems[0].text;
        }
        return 'Unknown';
    },

    // Get access level tag label
    getAccessLevelTagLabel: (accessLevel) => {
        if (accessLevel === 0) {
            return 'label-primary';
        }
        if (accessLevel === 1) {
            return 'label-outline-info';
        }
        return 'label-outline-secondary';
    },

    // Check access token valid
    checkAccessTokenValid: () => {
        const accessToken = localStorage.getItem(PreferenceKeys.accessToken);
        const accessTokenExpired = localStorage.getItem(PreferenceKeys.accessTokenExpired);

        if (accessToken && accessTokenExpired) {
            const momentExpired = moment.utc(accessTokenExpired);
            const momentNow = moment.utc();
            return momentExpired.isAfter(momentNow);
        }

        return false;
    },

    // Get display name and phone
    getDisplayNameAndPhone: (user: Account) => {
        let displayName = "";
        if (user) {
            displayName = user.fullname ?? user.email;

            if (user.phone) {
                displayName = displayName.concat(` - ${user.phone}`);
            }
        }
        return displayName;
    },
    // Get display name
    getDisplayName: function (user: Account) {
        let displayName = "";
        if (user) {
            return user.fullname ?? user.email;
        }
        return displayName;
    },


    // Sign out
    signOut: () => {
        localStorage.removeItem(PreferenceKeys.accessToken);
        localStorage.removeItem(PreferenceKeys.accessTokenExpired);
        removeAxiosAccessToken();
    },

    // default avatar
    getDefaultAvatar: () => {
        return AppImages.imgBlankAvatar;
    },

    // getBannerTypeName: (type) => {
    //     const { t } = useTranslation();
    //     let res = t("Default")
    //     switch (type) {
    //         case "BANNER_9_16":
    //             res = t("banner_9_16")
    //             break
    //         default:
    //             break
    //     }
    //     return res
    // },
};

export default UserHelper;
