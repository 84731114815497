import ruleApi from 'api/ruleApi';

const { createSlice, createAsyncThunk, current } = require("@reduxjs/toolkit");

export const thunkGetStage = createAsyncThunk('rule/getStage', async (params, thunkAPI) => {
    const res = await ruleApi.getStage(params);
    return res.data;
});

const getStageRuleSlice = createSlice({
    name: 'stages',
    initialState: {
        stages: [],
    },
    reducers: {
    },
    extraReducers: {
        // Get stages
        [thunkGetStage.fulfilled]: (state, action) => {
            const { } = action.payload;
            console.log(action.payload);
            // if (result === 'success') {
            //     if (rules) {
            //         state.rules = rules;
            //     }
            // }
        },
    }
});

const { reducer, actions } = getStageRuleSlice;
export default reducer;