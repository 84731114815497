import axiosClient from "./axiosClient";

const authApi = {
    // sign in
    signIn: (params) => {
        const url = '/account/login';
        return axiosClient.post(url, params);
    },

    // get current account info
    getCurrentUserInfo: () => {
        const url = '/account/profile';
        return axiosClient.get(url);
    },

    // sign up
    // signUp: (params) => {
    //     const url = '/account/register';
    //     return axiosClient.post(url, params);
    // },

    // sign out
    signOut: (params) => {
        const url = '/account/logout';
        return axiosClient.post(url, params);
    }
}

export default authApi;