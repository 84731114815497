// api/axiosClient.js
import axios from 'axios';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import ToastHelper from 'general/helpers/ToastHelper';
import UserHelper from 'general/helpers/UserHelper';
import queryString from 'query-string';

const sTag = '[AxiosClient]';

// Cai dat config mac dinh cho http request
// Tham khao: `https://github.com/axios/axios#request-config` 
// de xem chi tiet
// console.log(process.env.REACT_APP_API_URL, process.env.REACT_APP_BASE_URL);
const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'content-type': 'application/json'
    },
    paramsSerializer: params => queryString.stringify(params)
})

axiosClient.interceptors.request.use(async (config) => {
    console.log(`${sTag} - ${config.baseURL}${config.url}, ${config.method}, ${config.method === 'post' || config.method === 'put' ? JSON.stringify(config.data) : JSON.stringify(config.params)}`);
    console.log(`${sTag} - headers: ${JSON.stringify(config.headers.common)}`);
    const accessToken = localStorage.getItem(PreferenceKeys.accessToken);
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config
})

axiosClient.interceptors.response.use((response) => {
    if (response && response.data) {
        return { data: response.data, headers: response.headers }
    }

    return response
}, (error) => {
    // Bat loi xay ra
    let errorMessage = null;
    const response = error.response;
    if (response && response.data) {
        const data = response.data;
        const { result, reason } = data;
        if (result === 'failed' && reason) {
            errorMessage = reason;
        }
    }
    if (errorMessage) {
        ToastHelper.showError(errorMessage);
    }
    throw error
})

// Update base url
const updateAxiosBaseURL = (baseUrl) => {
    axiosClient.defaults.baseURL = baseUrl;
};

// Update access token
const updateAxiosAccessToken = (accessToken) => {
    axiosClient.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
};

// Remove access token
const removeAxiosAccessToken = () => {
    delete axiosClient.defaults.headers.common['Authorization'];
};

(() => {
    const isTokenValid = UserHelper.checkAccessTokenValid();
    if (isTokenValid) {
        const token = localStorage.getItem(PreferenceKeys.accessToken);
        updateAxiosAccessToken(token);
    } else {
        UserHelper.signOut();
    }
})();

export {
    updateAxiosAccessToken,
    removeAxiosAccessToken,
    updateAxiosBaseURL,
};

export default axiosClient