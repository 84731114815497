import authApi from 'api/authApi';
import PreferenceKeys from 'general/constants/PreferenceKeys';

const { createSlice, createAsyncThunk } = require("@reduxjs/toolkit");

// MARK: --- Thunks ---
// Sign in
export const thunkSignIn = createAsyncThunk('auth/signIn', async (params, thunkAPI) => {
    const res = await authApi.signIn(params);
    return res.data;
});

// Get current user info
export const thunkGetCurrentUserInfo = createAsyncThunk('auth/getCurrentUserInfo', async (params, thunkAPI) => {
    const res = await authApi.getCurrentUserInfo(params);
    return res.data;
});

// Sign out
export const thunkSignOut = createAsyncThunk('auth/signOut', async function (params, thunkAPI) {
    const res = await authApi.signOut(params);
    return res.data;
});

const authSlice = createSlice({
    name: 'auth',
    initialState: {
        isSigningIn: false,
        current: {},
    },
    reducers: {
        signOut: (state, action) => {
            state.error = '';
            state.current = {};
        }
    },
    extraReducers: {
        // Sign in
        [thunkSignIn.pending]: (state, action) => {
            state.isSigningIn = true;
        },
        [thunkSignIn.rejected]: (state, action) => {
            state.isSigningIn = false;
            state.error = action.error;
        },
        [thunkSignIn.fulfilled]: (state, action) => {
            state.isSigningIn = false;
            const data = action.payload;
            console.log({ data });
            const token = data.token;
            if (data) {
                state.current = data;

                if (data.expirationDate) {
                    localStorage.setItem(PreferenceKeys.accessTokenExpired, data.expirationDate);
                }
            }
            if (token) {
                localStorage.setItem(PreferenceKeys.accessToken, token);
            }
        },

        // Get current user info
        [thunkGetCurrentUserInfo.fulfilled]: (state, action) => {
            const data = action.payload;
            if (data) {
                state.current = data;
            }
        },

        // Sign out
        [thunkSignOut.fulfilled]: (state, action) => {
            localStorage.removeItem(PreferenceKeys.accessToken);
            localStorage.removeItem(PreferenceKeys.accessTokenExpired);
            const data = action.payload;
            console.log({ data });
            if (data) {
                state.current = {};
            }
        },
    }
});

const { reducer, actions } = authSlice;
export const { signOut } = actions;
export default reducer;