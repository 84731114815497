import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import './style.scss';
import 'assets/css/pages/login/login-3.css';
import { AppIcons } from 'general/constants/AppResource';
import SignInForm from 'features/Auth/components/SignInForm';
import ToastHelper from 'general/helpers/ToastHelper';
import { useDispatch } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import { thunkSignIn } from 'app/authSlice';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import AppData from 'general/constants/AppData';
import PreferenceKeys from 'general/constants/PreferenceKeys';
import Utils from 'general/utils/Utils'
import _ from "lodash"

SignInScreen.propTypes = {

};

function SignInScreen(props) {
    // MARK: --- Params ---
    const sTag = '[SignInScreen]';
    const history = useHistory();
    const dispatch = useDispatch();
    const { t, i18n } = useTranslation();
    let currentAppLanguage = i18n.language;
    if (!currentAppLanguage) {
        currentAppLanguage = 'en';
    }
    let currentAppLanguageItem = AppData.languages.find(item => currentAppLanguage.indexOf(item.code) !== -1);
    if (!currentAppLanguageItem) {
        currentAppLanguageItem = AppData.languages[0];
    }
    const [languageMenuVisible, setLanguageMenuVisible] = useState(false);

    // MARK: --- Functions ---
    async function handleSubmit(values) {

        // console.log(`${sTag} on submit: ${JSON.stringify(values)}`);
        const params = values;
        try {
            const res = unwrapResult(await dispatch(thunkSignIn({ ...params, 'password': _.get(values, 'password') })));
            console.log(res);
            if (res && res.token) {
                ToastHelper.showSuccess(`${t('Hello')}, ${res.fullname ?? 'Admin'}`);
                history.replace('/');
                // debugger
            }
        } catch (error) {
            console.log(`${sTag} Failed to login: ${error.message}`);
            ToastHelper.showError(error.message);
            localStorage.removeItem(PreferenceKeys.accessToken);
            localStorage.removeItem(PreferenceKeys.accessTokenExpired);
        }
    }

    function handleSignInGoogle() {
        console.log(`${sTag} on sign in Google`);
    }

    function handleChangedLanguage(lang) {
        setLanguageMenuVisible(false);
        i18n.changeLanguage(lang);
    }

    function toggleLanguageMenu() {
        setLanguageMenuVisible(!languageMenuVisible);
    }

    // MARK: --- Hooks ---
    useEffect(() => {
        console.log(`${sTag} viewDidLoad`);

        return () => {
            console.log(`${sTag} viewWillDismiss`);
        };
    }, []);

    return (
        <div className="d-flex flex-column flex-root" style={{ height: "100vh" }}>
            {/* begin::Sign In */}
            <div className="login login-3 wizard d-flex flex-column flex-lg-row flex-column-fluid">
                {/* begin::Aside */}
                <div className="login-aside d-flex flex-column flex-row-auto" style={{ background: "linear-gradient(302deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%)" }}>
                    {/* begin::Aside Top */}
                    <div className="d-flex flex-column-auto flex-column pt-lg-40 pt-15 pb-15 pb-lg-0">
                        {/* begin::Aside Header */}
                        <a href="#" className="text-center mb-15">
                            <img src={AppIcons.icLogo} alt="logo" className="h-160px rounded-circle" />
                        </a>
                        {/* begin::Aside Header */}
                        {/* begin::Aside Title */}
                        <h3 className="font-weight-bolder text-center font-size-h4 font-size-h1-lg text-white">{t('AppName')}</h3>
                        {/* begin::Aside Title */}
                    </div>
                    {/* end::Aside Top */}

                    {/* begin::Aside Bottom */}
                    <div className="Login__aside_bottom aside-img d-none d-lg-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"></div>
                    {/* end::Aside Bottom */}
                </div>
                {/* end::Aside */}

                {/* begin::Content */}
                <div className="login-content flex-row-fluid d-flex flex-column p-10">
                    {/* begin::Top */}
                    <div className="text-right d-flex justify-content-center align-items-center">
                        <div className="top-signin text-right d-flex justify-content-end pb-lg-0">
                            <span className="font-weight-bold text-muted font-size-h5">{`${t('ChangeLanguage')}`}</span>
                            {/* <a href="#" className="font-weight-bold text-primary font-size-h5 ml-2" id="kt_login_signup">{t('Help')}</a> */}
                        </div>
                        {/* Language */}
                        <div className="">
                            {/* Languages */}
                            <Dropdown
                                isOpen={languageMenuVisible}
                                toggle={toggleLanguageMenu}
                                className="ml-2"
                            >
                                <DropdownToggle caret={false} className="border-0 p-0 bg-transparent">
                                    <div className="topbar-item" data-toggle="dropdown" data-offset="10px,0px" aria-expanded={`${languageMenuVisible ? 'true' : 'false'}`}>
                                        <a href='#' className="btn btn-icon btn-bg-white btn-hover-primary btn-icon-primary btn-circle btn-dropdown" onClick={toggleLanguageMenu}>
                                            <img className="h-20px w-20px rounded-circle" src={currentAppLanguageItem.icon} alt="" />
                                        </a>
                                    </div>
                                </DropdownToggle>
                                <DropdownMenu className='py-4'>
                                    {
                                        AppData.languages.map((item, index) => {
                                            return (
                                                <DropdownItem
                                                    key={index}
                                                    active={item.code === currentAppLanguageItem.code}
                                                    onClick={() => {
                                                        handleChangedLanguage(item.code);
                                                    }}
                                                >
                                                    <span className="symbol symbol-20 mr-3">
                                                        <img src={item.icon} alt="" />
                                                    </span>
                                                    <span className="navi-text">{t(item.title)}</span>
                                                </DropdownItem>
                                            );
                                        })
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                    {/* end::Top */}

                    {/* begin::Wrapper */}
                    <div className="d-flex flex-row-fluid flex-center">
                        {/* begin::Sign In */}
                        <div className="login-form">
                            {/* begin: Form */}
                            <SignInForm
                                onSubmit={handleSubmit}
                                onSignInGoogle={handleSignInGoogle}
                            />
                            {/* end: Form */}
                        </div>
                        {/* end::Sign In */}
                    </div>
                    {/* end::Wrapper */}
                </div>
                {/* end::Content */}
            </div>
            {/* end::Sign In */}
        </div>
    );
}

export default SignInScreen;