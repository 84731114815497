import moment from "moment";
import { parseUrl } from "query-string";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash"

const Utils = {
  // Get full url
  getFullUrl: (url) => {
    if (url && !url.startsWith("http") && !url.startsWith("blob")) {
      return `${process.env.REACT_APP_BASE_URL}${url}`;
    }
    return url;
  },

  // Check object empty
  isObjectEmpty: (obj) => {
    return (
      Utils.isObjectNull(obj) ||
      (Object.keys(obj).length === 0 && obj.constructor === Object)
    );
  },

  // Check object null|undefine
  isObjectNull: (obj) => {
    return obj === null || obj === undefined || obj === "NULL";
  },

  // convert first character of string to uppercase
  convertFirstCharacterToUppercase: (stringToConvert) => {
    var firstCharacter = stringToConvert.substring(0, 1);
    var restString = stringToConvert.substring(1);
    return firstCharacter.toUpperCase() + restString;
  },

  /**
   *
   * @param {Number} level
   * @returns
   */
  accesLevelToText: (level: 0 | 1 | 2 | 3) => {
    switch (level) {
      case 0:
        return "Admin";
      case 1:
        return "Subscribed";
      case 2:
        return "Registered";
      case 3:
      default:
        return "Guest";
    }
  },
  // format number
  formatNumber: (iNumber) => {
    return new Intl.NumberFormat("de-DE").format(iNumber);
  },

  /* sleep */
  sleep: async (ms: Number) => {
    return new Promise((resolve, reject) => {
      setTimeout(resolve, ms);
    });
  },
  /* validate url */
  validateUrl(value: String) {
    if (!_.isString(value)) return false;
    return /^(ftp|http|https):\/\/[^ "]+$/.test(value);
    // return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(value)
  },

  // format date time
  formatDateTime: (sDateTime, sFormat = "DD/MM/YYYY HH:mm") => {
    return moment(sDateTime).format(sFormat);
  },

  // Change empty to null
  formatEmptyKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === "") {
        items[key] = null;
      }
    }
  },

  // remove null key
  removeNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === null) {
        delete items[key];
      }
    }
  },

  // Delete null
  formatNullKey: (items) => {
    for (const [key, value] of Object.entries(items)) {
      if (value === null) {
        delete items[key];
      }
    }
  },
  /**
   * return youtube video id if success
   */
  matchYoutubeUrl(url: String) {
    if (url) {
      var p =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var matches = url.match(p);
      if (matches) {
        return matches[1];
      }
    }
    return false;
  },
  async hash(string: String) {
    const utf8 = new TextEncoder().encode(string);
    return crypto.subtle.digest("SHA-256", utf8).then((hashBuffer) => {
      const hashArray = Array.from(new Uint8Array(hashBuffer));
      const hashHex = hashArray
        .map((bytes) => bytes.toString(16).padStart(2, "0"))
        .join("");
      return hashHex;
    });
  },
};

/**
 * custom hook to access query
 * @important **follow hook rules**
 * @returns {URLSearchParams}
 */
export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const randStr = function () {
  return Math.random().toString(36).substr(2); // remove `0.`
};

export function decodeHtml(html: any) {
  if (!_.isString(html)) return html;
  var txt = document.createElement("textarea");
  txt.innerHTML = html;
  return txt.value;
}

/**
 * scroll to top on mounted and history changed
 *
 * **MUST** place inside a router component where `useHistory` and `useEffect` is usable
 * ```js
 * export function(){
 *  ...
 *  useScrollToTop()
 *  ...
 *
 *  return <div></div>
 * }
 * ```
 * **remember to follow hook rules**
 */
export function useScrollToTop() {
  const history = useHistory();

  function scrollToTop() {
    return setTimeout(
      () => window?.scrollTo({ top: 0, left: 0, behavior: "smooth" }),
      0
    );
  }
  useEffect(() => {
    let timeout = scrollToTop();

    return history.listen(() => {
      let timeout = scrollToTop();
    });
  }, []);

  return null;
}

export default Utils;
